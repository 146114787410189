import { Box, CircularProgress } from '@mui/material';
import * as loaders from 'react-loader-spinner';


const LoadingSpinner = ({size} : {size?: number}) =>
{
	return <Box
		sx={{
			width: '100%',
			height:'100%',
			m: 'auto',
			display: 'flex'
		}}
	>
		<CircularProgress 
			sx={{
				m: 'auto',
				color: '#46af8f',
				height: '100%',
				width: '100%'
			}} 
			thickness={4}
			disableShrink
			size={size ?? 100}
		/>
	</Box>
}


export default LoadingSpinner;